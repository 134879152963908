import React from 'react';
import { Form, Modal, Message, Button } from 'semantic';
import { pick } from 'lodash';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import modal from 'helpers/modal';
import DateField from 'components/form-fields/Date';
import VenuesField from 'components/form-fields/Venues';

@modal
export default class EditBroadcast extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      touched: false,
      loading: false,
      error: null,
      broadcast: props.broadcast || {},
    };
  }

  isUpdate() {
    return !!this.props.broadcast?.id;
  }

  isClone() {
    return !!this.props.broadcast && !this.props.broadcast.id;
  }

  setField(name, value) {
    this.setState({
      broadcast: {
        ...this.state.broadcast,
        [name]: value,
      },
    });
  }

  onSubmit = async () => {
    const { broadcast } = this.state;

    this.setState({
      loading: true,
      touched: true,
    });

    const body = pick(broadcast, ['title', 'content', 'venue', 'publishAt']);

    try {
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/broadcasts/${broadcast.id}`,
          body,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/broadcasts',
          body,
        });
      }

      this.props.onSave();
      this.props.close();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { broadcast, touched, loading, error } = this.state;

    let header = 'New Broadcast';
    let action = 'Create';

    if (this.isUpdate()) {
      header = 'Edit Broadcast';
      action = 'Update';
    } else if (this.isClone()) {
      header = 'Clone Broadcast';
      action = 'Clone';
    }

    return (
      <>
        <Modal.Header>{header}</Modal.Header>

        <Modal.Content>
          <AutoFocus>
            <Form
              id="edit-broadcast"
              onSubmit={this.onSubmit}
              error={touched && !!error}>
              {error && <Message error content={error.message} />}

              <Form.Input
                value={broadcast.title || ''}
                label="Title"
                required
                type="text"
                onChange={(_e, { value }) => this.setField('title', value)}
              />

              <Form.Input
                value={broadcast.content || ''}
                label="Content"
                required
                maxLength="200"
                type="text"
                onChange={(_e, { value }) => this.setField('content', value)}
              />

              <VenuesField
                multiple={false}
                name="venue"
                value={broadcast.venue}
                onChange={(_e, { value }) => this.setField('venue', value)}
              />

              <DateField
                clearable
                time
                name="publishAt"
                label="Publish date"
                value={broadcast.publishAt}
                onChange={(_e, { value }) => this.setField('publishAt', value)}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>

        <Modal.Actions>
          <Button
            primary
            form="edit-broadcast"
            loading={loading}
            disabled={loading}
            content={action}
          />
        </Modal.Actions>
      </>
    );
  }
}
